import React, { useEffect } from "react";
import { useState } from "react";
import groovyWalkAnimation from "./printing_animation.json";
import Lottie from "lottie-react";
const PassbookPrintingPage = (props) => {
  const [queue, setQueue] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  function generateNbsps(count) {
    if (count <= 0) {
      return "";
    }
    return "&nbsp;".repeat(count);
  }

  const handlePrint = async () => {
    const str = "Balance Forwarded******";
    var date = "06/21/2024";
    var withdrawalAmount = "5,000.00";
    var depositAmount = "53,526.00";
    if (queue === null || queue === undefined) {
      return;
    }

    // Create a new window for printing
    const printWindow = window.open("", "", "width=600,height=600");
    printWindow.document.write("<html><head><title>Print</title>");
    printWindow.document.write(
      "<style>body { font-family: monospace; }</style>"
    );
    printWindow.document.write("</head><body>");
    //first 10 lines

    // for (let i = 0; i < queue.line; i++) {
    //   printWindow.document.write("<p> &nbsp</p>");
    // }

    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");

    // printWindow.document.write(
    //   `<p>${generateNbsps(2)}${str}${generateNbsps(
    //     64 - (str.length + 2)
    //   )}5,143,807.45</p>`
    // );
    // //deposit

    // printWindow.document.write(
    //   `<p>${generateNbsps(2)}${date}${generateNbsps(
    //     17 - (date.length + 2)
    //   )}${withdrawalAmount}${generateNbsps(
    //     47 - withdrawalAmount.length
    //   )}5,197,333.47</p>`
    // );
    {
      queue.map((item) =>
        printWindow.document.write(
          `<p>${generateNbsps(2)}${item?.date_of_transaction.replaceAll(
            " ",
            ""
          )}${generateNbsps(
            17 - item.date_of_transaction.replaceAll(" ", "").length
          )}${item?.withdrawal_amount.replaceAll(" ", "")}
          ${generateNbsps(
            24 - (item?.withdrawal_amount.replaceAll(" ", "").length + 2)
          )}${item.deposit_amount.replaceAll(" ", "")}${generateNbsps(
            item?.withdrawal_amount == ""
              ? 47
              : 23 - item.deposit_amount.replaceAll(" ", "").length
          )}${item?.balance.replaceAll(" ", "")}</p>`
        )
      );
    }
    //withdrawal
    // printWindow.document.write(
    //   `<p>${generateNbsps(2)}${date}${generateNbsps(
    //     17 - (date.length + 2)
    //   )}${withdrawalAmount}${generateNbsps(
    //     47 - withdrawalAmount.length
    //   )}5,197,333.47</p>`
    // );
    printWindow.document.write("<p> &nbsp</p>");

    printWindow.document.write("</body></html>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  // const getQueueX = async () => {
  //   try {
  //     const queryParams = new URLSearchParams(window.location.search);
  //     const id = queryParams.get("id");
  //     const token = queryParams.get("token");
  //     var res = await getQueue(id, token);
  //     setQueue(res.data);
  //     console.log(res);
  //     return;
  //   } catch (err) {
  //     console.log(err);
  //     return;
  //   }
  // };

  // const getQueueX = async () => {};

  useEffect(() => {
    try {
      setTimeout(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const data = queryParams.get("data");
        var parsedData = JSON.parse(data);

        if (typeof parsedData === "string") {
          parsedData = JSON.parse(parsedData);
        }
        console.log(parsedData);
        setQueue(parsedData);
      }, 2000);

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);

      // setTimeout(async () => {
      //   if (queue === null || queue === undefined || isLoading) {
      //     return;
      //   }
      //   handlePrint();
      // }, 2000);

      return;
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      return;
    }
  }, []); // Remove isLoading and queue from the dependency array

  return (isLoading && queue != null) || queue === undefined ? (
    <div
      style={{ alignItems: "center", height: "100vh", alignContent: "center" }}
    >
      <center>
        <h1>Loading...</h1>
      </center>
    </div>
  ) : (
    <div
      style={{ alignItems: "center", height: "100vh", alignContent: "center" }}
    >
      <center>
        <div style={{ width: 200, height: 180 }}>
          <Lottie animationData={groovyWalkAnimation} loop={true} width={80} />
        </div>
      </center>
      <center>
        <p style={{ fontSize: 24, fontWeight: 600 }}>
          Printing in progress. Please wait...
        </p>
        <button
          style={{
            padding: "10px 20px",
            fontSize: 12,
            fontWeight: "bold",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: 4,
            marginTop: 20,
            cursor: "pointer",
          }}
          onClick={handlePrint}
        >
          Print Now
        </button>
      </center>
    </div>
  );
};
export default PassbookPrintingPage;
