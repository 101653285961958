import React, { useEffect } from "react";
import { useState } from "react";
import groovyWalkAnimation from "./printing_animation.json";
import Lottie from "lottie-react";
const PrintPage = (props) => {
  const [queue, setQueue] = useState({});
  const [linesToSkip, setLinesToSkip] = useState(37);
  const [isLoading, setIsLoading] = useState(true);

  function generateNbsps(count) {
    return "&nbsp;".repeat(count);
  }

  function convertNumberToWord(number) {
    const units = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];

    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    if (number === 0) {
      return units[0];
    }

    if (number < 20) {
      return units[number];
    }

    if (number < 100) {
      const digitOne = Math.floor(number / 10);
      const digitTwo = number % 10;
      return tens[digitOne] + (digitTwo !== 0 ? " " + units[digitTwo] : "");
    }

    if (number < 1000) {
      const digitOne = Math.floor(number / 100);
      const digitTwo = number % 100;
      return (
        units[digitOne] +
        " Hundred" +
        (digitTwo !== 0 ? " " + convertNumberToWord(digitTwo) : "")
      );
    }

    if (number < 1000000) {
      const digitOne = Math.floor(number / 1000);
      const digitTwo = number % 1000;
      return (
        convertNumberToWord(digitOne) +
        " Thousand" +
        (digitTwo !== 0 ? " " + convertNumberToWord(digitTwo) : "")
      );
    }

    if (number < 1000000000) {
      const digitOne = Math.floor(number / 1000000);
      const digitTwo = number % 1000000;
      return (
        convertNumberToWord(digitOne) +
        " Million" +
        (digitTwo !== 0 ? " " + convertNumberToWord(digitTwo) : "")
      );
    }

    const digitOne = Math.floor(number / 1000000000);
    const digitTwo = number % 1000000000;
    return (
      convertNumberToWord(digitOne) +
      " Billion" +
      (digitTwo !== 0 ? " " + convertNumberToWord(digitTwo) : "")
    );
  }
  function getCurrencyString(currency) {
    switch (currency) {
      case "peso_bill_1000":
        return "1000 peso/s";
      case "peso_bill_500":
        return "500 peso/s";
      case "peso_bill_200":
        return "200 peso/s";
      case "peso_bill_100":
        return "100 peso/s";
      case "peso_bill_50":
        return "50 peso/s";
      case "peso_bill_20":
        return "20 peso/s";
      case "peso_coin_20":
        return "20 peso coin/s";
      case "peso_coin_10":
        return "10 peso coin/s";
      case "peso_coin_5":
        return "5 peso coin/s";
      case "peso_coin_1":
        return "1 peso coin/s";
      case "peso_coin_25c":
        return "25 centavo coin/s";
      case "peso_coin_10c":
        return "10 centavo coin";
      case "peso_coin_5c":
        return "5 centavo coin/s";
      default:
        return "";
    }
  }

  // function amountAsterisks(amount) {
  //   const remaining = 17 - amount.length;
  //   return "*".repeat(remaining) + amount;
  // }

  function generateArrayByNumber(n) {
    return Array.from({ length: n }, (_, i) => i + 1);
  }

  const handlePrint = async () => {
    if (queue === null || queue === undefined) {
      return;
    }
    const currentDate = new Date().toLocaleDateString();
    // Create a new window for printing
    const printWindow = window.open("", "", "width=600,height=600");
    printWindow.document.write("<html><head><title>Print</title>");
    printWindow.document.write(
      "<style>body { font-family: monospace; }</style>"
    );
    printWindow.document.write("</head><body>");
    //first 10 lines
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    //first 10 lines
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");

    const accountName = queue?.account_name;
    if (accountName && accountName.length > 29) {
      const firstLine = accountName.substring(0, 29);
      const secondLine = accountName.substring(29);
      printWindow.document.write(
        `<p>${generateNbsps(4)}Account Name${generateNbsps(18)}${firstLine}</p>`
      );
      printWindow.document.write(`<p>${generateNbsps(34)}${secondLine}</p>`);
    } else {
      printWindow.document.write(
        `<p>${generateNbsps(4)}Account Name${generateNbsps(
          18
        )}${accountName}</p>`
      );
    }
    printWindow.document.write(
      `<p>${generateNbsps(4)}Account Number${generateNbsps(16)}${
        queue?.account_number
      }</p>`
    );
    printWindow.document.write(
      `<p>${generateNbsps(4)}Amount${generateNbsps(24)}${queue?.amount}</p>`
    );
    printWindow.document.write(
      `<p>${generateNbsps(4)}Transaction Code${generateNbsps(14)}${
        queue?.transaction_code
      }</p>`
    );
    printWindow.document.write(
      `<p>${generateNbsps(4)}Teller ID${generateNbsps(21)}${
        queue?.teller_id
      }</p>`
    );
    printWindow.document.write(
      `<p>${generateNbsps(4)}Transaction Date Time${generateNbsps(9)}${
        queue?.date_time
      }</p>`
    );
    printWindow.document.write(
      `<p>${generateNbsps(4)}Branch${generateNbsps(24)}${queue?.branch}</p>`
    );
    printWindow.document.write(
      `<p>${generateNbsps(4)}Reference #${generateNbsps(19)}${
        queue?.reference_number
      }</p>`
    );
    //first 10 lines
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    //first 10 lines
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    if (accountName && accountName.length > 29) {
    } else {
      printWindow.document.write("<p> &nbsp</p>");
    }
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };
  const handlePrintOfficial = async () => {
    var amount = queue?.amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (queue === null || queue === undefined) {
      return;
    }
    const currentDate = new Date().toLocaleDateString();
    // Create a new window for printing
    const printWindow = window.open("", "", "width=612,height=1008");
    printWindow.document.write("<html><head><title>Print</title>");
    printWindow.document.write(
      "<style>body { font-family: monospace; }</style>"
    );
    printWindow.document.write("</head><body>");
    //first 10 lines
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // //first 10 lines
    // //first 10 lines
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");

    // //first 10 lines
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // //removed
    // //first 10 lines
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");

    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");

    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");
    // printWindow.document.write("<p> &nbsp</p>");

    if (queue?.transaction_code?.toLowerCase() === "dpst") {
      for (let i = 0; i < linesToSkip; i++) {
        printWindow.document.write("<p> &nbsp</p>");
      }
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");

      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");

      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");

      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");

      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");

      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");

      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");

      // printWindow.document.write("<p>&nbsp;</p>");
      // printWindow.document.write("<p>&nbsp;</p>");
    }

    printWindow.document.write(
      `<p>${generateNbsps(4)}Acct. Name: ${generateNbsps(1)}${
        queue?.account_name
      }</p>`
    );
    printWindow.document.write(
      `<p>${generateNbsps(4)}Acct. #:${generateNbsps(1)}${
        queue?.account_number
      }${generateNbsps(2)}Amount: ${generateNbsps(1)}${amount}</p>`
    );

    printWindow.document.write(
      `<p>${generateNbsps(4)}Tran. Code: ${generateNbsps(1)}${
        queue?.transaction_code
      }${generateNbsps(2)}Teller ID:${generateNbsps(1)}${queue?.teller_id}</p>`
    );

    printWindow.document.write(
      `<p>${generateNbsps(4)}Tran. Date Time:${generateNbsps(2)}${
        queue?.date_time
      }${generateNbsps(2)}Branch:${generateNbsps(1)}${queue?.branch}</p>`
    );

    printWindow.document.write(
      `<p>${generateNbsps(4)}Ref. #: ${generateNbsps(1)}${
        queue?.reference_number
      }</p>`
    );

    printWindow.document.write("<p>&nbsp;</p>");
    printWindow.document.write("<p>&nbsp;</p>");
    printWindow.document.write("<p>&nbsp;</p>");

    printWindow.document.write("<p>&nbsp;</p>");
    printWindow.document.write("<p>&nbsp;</p>");
    printWindow.document.write("<p>&nbsp;</p>");
    printWindow.document.write("<p>&nbsp;</p>");
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };
  // const getQueueX = async () => {
  //   try {
  //     const queryParams = new URLSearchParams(window.location.search);
  //     const id = queryParams.get("id");
  //     const token = queryParams.get("token");
  //     var res = await getQueue(id, token);
  //     setQueue(res.data);
  //     console.log(res);
  //     return;
  //   } catch (err) {
  //     console.log(err);
  //     return;
  //   }
  // };

  // const getQueueX = async () => {};

  useEffect(() => {
    try {
      setTimeout(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const data = queryParams.get("data");
        var parsedData = JSON.parse(data);

        if (typeof parsedData === "string") {
          parsedData = JSON.parse(parsedData);
        }
        console.log(parsedData);
        setQueue(parsedData);
      }, 2000);

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);

      // setTimeout(async () => {
      //   if (queue === null || queue === undefined || isLoading) {
      //     return;
      //   }
      //   handlePrint();
      // }, 2000);

      return;
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      return;
    }
  }, []); // Remove isLoading and queue from the dependency array

  return (isLoading && queue != null) || queue === undefined ? (
    <div
      style={{ alignItems: "center", height: "100vh", alignContent: "center" }}
    >
      <center>
        <h1>Loading...</h1>
      </center>
    </div>
  ) : (
    <div
      style={{ alignItems: "center", height: "100vh", alignContent: "center" }}
    >
      <center>
        <div style={{ width: 200, height: 180 }}>
          <Lottie animationData={groovyWalkAnimation} loop={true} width={80} />
        </div>
      </center>
      <center>
        <p style={{ fontSize: 24, fontWeight: 600 }}>
          Printing in progress. Please wait...
        </p>
        <br></br>
        {queue?.transaction_code?.toLowerCase() === "dpst" ? (
          <input
            type="number"
            placeholder="Enter text"
            value={linesToSkip}
            onChange={(e) => {
              setLinesToSkip(parseInt(e.target.value));
            }}
          />
        ) : (
          <div></div>
        )}
        <br></br>
        <button
          style={{
            padding: "10px 20px",
            fontSize: 12,
            fontWeight: "bold",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: 4,
            marginTop: 20,
            cursor: "pointer",
          }}
          onClick={handlePrintOfficial}
        >
          Print Now
        </button>
        {/* <br></br>
        <button
          style={{
            padding: "10px 20px",
            fontSize: 12,
            fontWeight: "bold",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: 4,
            marginTop: 10,
            cursor: "pointer",
          }}
          onClick={handlePrint}
        >
          Print Official Validation
        </button> */}
      </center>
    </div>
  );
};
export default PrintPage;
